import { createAction, props } from '@ngrx/store';
import { Attendance, Channel, Company, CountState, Department, Integration, Message, Note, Pager, SearchParams, SettingsModule, Tag, User } from 'lib-trend-core';
import { PagerParamsState } from './app.state';

// app state
export const clearFullState = createAction('[Clear] Full state', props<{ clear: boolean }>());

export const getLoadInit = createAction('[Init] Load initial', props<{ idUser: string, idCompany: string, cached?: boolean }>());
export const setLoadInit = createAction('[Init] Set initial', props<{ user: User, company: Company, cached?: boolean }>());

export const getCounts = createAction('[Attendance] Get Counts', props<{ search?: string, params?: Partial<SearchParams> }>());
export const getAttendances = createAction("[Attendance] Get Attendances", props<{ page: number, perPage: number, search?: string, params?: Partial<SearchParams>, cached?: boolean }>());
export const getAttendance = createAction('[Attendance] Get Attendance', props<{ idAttendance: string, cached?: boolean }>());
export const getMessages = createAction('[Message] Get Messages', props<{ page: number, perPage: number, idAttendance: string, idCompany: string, cached?: boolean }>());
export const getNotes = createAction('[Note] Get notes', props<{ idContact: string, idAttendance: string, cached?: boolean }>());
export const getTags = createAction('[Tag] Get tags', props<{ idCompany: string, cached?: boolean }>());
export const getIntegrations = createAction('[Integration] Get Integrations', props<{ ptype: string, cached?: boolean }>());
export const getSettingsModules = createAction('[Settings Module] Get Settings Module', props<{ idCompany: string; cached?: boolean }>());
export const getSimpleLists = createAction('[Tag] Get Simple lists', props<{ idCompany: string }>());

export const setPagerParams = createAction('[Attendance PagerParams] Set PagerParams of Attendance', props<{ pagerParams: PagerParamsState }>());
export const setCounts = createAction('[Tag] Set Counts of Attendance', props<{ counts: Array<CountState> }>());
export const setAttendances = createAction('[Attendance] Set Attendances Success', props<{ attendances: Pager<Attendance> }>());
export const setAttendance = createAction('[Attendance] Set Attendance', props<{ attendance: Attendance }>());
export const setNotes = createAction('[Attendance Note] Get Note of Attendance', props<{ notes: Array<Note> }>());
export const setMessages = createAction('[Attendance Message] Get Messages of Attendance', props<{ messages: Pager<Message> }>());
export const setTags = createAction('[Tag] Set Tags of Attendance', props<{ tags: Array<Tag> }>());
export const setIntegrations = createAction('[Integration] Set Integrations of a Company', props<{ integrations: Array<Integration>, hasPipedriveIntegration: boolean }>());
export const setSettingsModules = createAction('[Settings Module] Set Settings Module of a Company', props<{ settingsModules: Array<SettingsModule> }>());
export const setSimpleLists = createAction('[Cache] Set Simple Lists ', props<{ departaments: Array<Department>, channels: Array<Channel>, tags: Array<Tag>, users: Array<User> }>());

export const setFailure = createAction('[Failure] Error', props<{ error: string }>());

export const openCloseSidebarRight = createAction('[Sidebar] Open/Close', props<{ sidebarRight: boolean }>());
export const openCloseContactInfo = createAction('[ContactInfo] Open/Close', props<{ contactInfo: boolean }>());

