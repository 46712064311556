import { Component, ElementRef, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractComponent, Attendance, AttendanceService, Message, MessageService, Pager, UtilHelper } from 'lib-trend-core';
import { takeUntil } from 'rxjs';

export interface AttendanceHistoryData {
  idContact: string;
  idAttendance: string;
  openAttendanceHistoryUnique: boolean;
}

@Component({
  templateUrl: 'attendance-history.component.html',
  styleUrls: ['./attendance-history.component.scss']
})
export class AttendanceHistoryComponent extends AbstractComponent implements OnInit {
  @ViewChild('messageContainer') messageContainer: ElementRef<HTMLDivElement>;

  public attendanceUnique: Attendance | null = null;

  pager: Pager<Attendance> = new Pager<Attendance>({ perPage: 11 });
  attendances: Attendance[] = [];
  filteredAttendances: Attendance[] = [];

  messages: Array<Message> = new Array<Message>();

  public pagerMessage: Pager<Message> = new Pager<Message>({ perPage: 10 });

  constructor(
    public injector: Injector,
    private attendanceService: AttendanceService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: AttendanceHistoryData,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.searchParams = {
      company: super.getIDCurrentCompany(),
      contact: this.data.idContact,
    };

    if (this.data.openAttendanceHistoryUnique) {
      this.searchParams._id = this.data.idAttendance;
    }

    this.attendanceService.getAttendanceHistory(
      this.pager.page,
      this.pager.perPage,
      this.searchString,
      this.searchParams
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (pager: Pager<Attendance>) => {
          this.pager = pager;
          if (this.data.idAttendance) {
            const attendance = pager.list.find(a => a._id === this.data.idAttendance);
            if (attendance) {
              this.updateMessages(attendance._id);
            }
          }
        },
      });
  }


  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  formatWhatsappMessage(text: string): string {
    return UtilHelper.formatWhatsappMessage(text);
  }

  updateMessages(idAttendance: string, newPage?: number) {
    this.messageService.getAll(
      newPage ? newPage : this.pager.page,
      this.pager.perPage,
      '',
      { attendance: idAttendance }
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (pager: Pager<Message>) => {
          this.pagerMessage.page = pager.page;
          this.pagerMessage.perPage = pager.perPage;
          this.pagerMessage.total = pager.total;
          this.pagerMessage.previousPage = pager.previousPage;
          this.pagerMessage.list = pager.page === 1 ? pager.list : [...this.pagerMessage.list, ...pager.list];
          if (this.pagerMessage.total > 0) {
            this.pagerMessage.list = [...this.pagerMessage.list].sort((a, b) => {
              const d1 = new Date(a.createdAt).getTime();
              const d2 = new Date(b.createdAt).getTime();
              return (d1 > d2) ? 1 : -1;
            });
          }
          this.messages = this.pagerMessage.list;
        },
      });
  }

  getFormatDate(tma: number): string {
    if (typeof tma !== 'number' || isNaN(tma) || tma < 0) {
      return '-';
    }
    const totalSeconds = tma * 60;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const secs = Math.floor(totalSeconds % 60);
    return `${hours}h ${minutes}m ${secs}s`;
  }

  loadPageMessage(idAttendance: string): void {
    if (this.pagerMessage.list.length < this.pagerMessage.total) {
      this.updateMessages(idAttendance, this.pagerMessage.page + 1);
    }
  }

  calculateDuration(startDate: string | Date, endDate: string | Date): string {
    if (!startDate) {
      return '-';
    }

    const start = new Date(startDate).getTime();
    const end = endDate ? new Date(endDate).getTime() : new Date().getTime();

    if (isNaN(start) || isNaN(end)) {
      return '-';
    }

    const diffInMs = end - start;

    if (diffInMs < 0) {
      return '-';
    }

    const seconds = Math.floor((diffInMs / 1000) % 60);
    const minutes = Math.floor((diffInMs / (1000 * 60)) % 60);
    const hours = Math.floor(diffInMs / (1000 * 60 * 60));

    return `${hours}h ${minutes}m ${seconds}s`;
  }
}

