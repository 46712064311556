<div>
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#D1FADF" />
    <rect width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path
      d="M14.9338 20.5H25.9338V22.5H14.9338V20.5ZM14.9338 18.5H25.9338V16.5H14.9338V18.5ZM14.9338 26.5H21.9338V24.5H14.9338V26.5ZM29.9438 23.37L30.6538 22.66C31.0438 22.27 31.6738 22.27 32.0638 22.66L32.7738 23.37C33.1638 23.76 33.1638 24.39 32.7738 24.78L32.0638 25.49L29.9438 23.37ZM29.2338 24.08L23.9338 29.38V31.5H26.0538L31.3538 26.2L29.2338 24.08Z"
      fill="#039855" />
  </svg>

  <h1 class="font-black text-[20px] text-black mt-2.5">Histórico de atendimento - {{pager.list[0]?.contact?.name}}
    {{pager.list[0]?.contact?.phone}}</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Veja abaixo todas as interações e mensagens trocadas durante o
    atendimento</h6>
</div>

<ng-container *ngIf="!data.openAttendanceHistoryUnique; else uniqueAccordion">
  <div style="height: 100%; overflow-y: auto">
    <div class="p-2">
      <mat-accordion>
        <div
          class="header-row grid grid-cols-9 gap-4 bg-[#cdcdcd] table-title p-2 border-t-2 border-gray-300 rounded-t-lg">
          <p class="text-center font-medium">Início</p>
          <p class="text-center font-medium">Encerramento</p>
          <p class="text-center font-medium">Status</p>
          <p class="text-center font-medium">Usuário</p>
          <p class="text-center font-medium">Departamento</p>
          <p class="text-center font-medium">TME</p>
          <p class="text-center font-medium">TMA</p>
          <p class="text-center font-medium">TTA</p>
        </div>
        <mat-expansion-panel *ngFor="let attendance of pager.list" hideToggle
          [expanded]="attendance._id === data.idAttendance">

          <mat-expansion-panel-header class="bg-[#fafbff]" (click)="updateMessages(attendance._id)">
            <div class="w-full grid grid-cols-9 gap-4 items-center">
              <div class="text-center">
                <p>{{ attendance.createdAt | date:'dd/MM/yyyy HH:mm' }}</p>
              </div>

              <div class="text-center">
                <p *ngIf="attendance.status === 'CLOSED' || attendance.status === 'OVERTIME'">
                  {{ attendance.closedAt | date:'dd/MM/yyyy HH:mm' }}
                </p>
                <p *ngIf="attendance.status !== 'CLOSED' && attendance.status !== 'OVERTIME'">-</p>
              </div>

              <div class="text-center">
                <span *ngIf="attendance.status === 'CLOSED'"
                  class="px-[10px] py-[5px] font-light text-white rounded-lg bg-gray-500">
                  Encerrado
                </span>
                <span *ngIf="attendance.status === 'OVERTIME'"
                  class="px-[10px] py-[5px] font-light text-white rounded-lg bg-[#EF4444]">
                  Fora de horário
                </span>
                <span *ngIf="attendance.status === 'PENDING'"
                  class="px-[10px] py-[5px] font-dark text-white rounded-lg bg-orange-500">
                  Aguardando
                  <span *ngIf="attendance.chatbotStatus.automated" class="ml-2 px-[10px] py-[5px] bg-black">
                    Chatbot
                  </span>
                </span>
                <span *ngIf="attendance.status === 'IN_PROGRESS'"
                  class="px-[10px] py-[5px] font-light text-white rounded-lg bg-green-500">
                  Em andamento
                </span>
                <span *ngIf="attendance.status === 'PAUSED'"
                  class="px-[10px] py-[5px] font-light text-white rounded-lg bg-[#3B82F6]">
                  Pausado
                </span>
              </div>

              <div class="text-center">
                <p>{{attendance?.user?.name ?? '-'}}</p>
              </div>

              <div class="text-center">
                <p class="text-xs text-gray-500">{{attendance?.department?.name ?? '-'}}</p>
              </div>

              <div class="text-center">
                <p class="text-xs text-gray-500">{{getFormatDate(attendance?.kpis?.tme) ?? '-'}}</p>
              </div>

              <div class="text-center">
                <p class="text-xs text-gray-500">{{getFormatDate(attendance?.kpis?.tma) ?? '-'}}</p>
              </div>

              <div class="text-center">
                <p class="text-xs text-gray-500">{{getFormatDate(attendance?.kpis?.tmta) ?? '-'}}</p>
              </div>
            </div>
          </mat-expansion-panel-header>

          <chat-messages-component [pager]="pagerMessage" [attendance]="attendance" [messages]="messages"
            (messageLoadPage)="loadPageMessage(attendance._id)">
          </chat-messages-component>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</ng-container>

<ng-template #uniqueAccordion>
  <div style="height: 100%; overflow-y: auto">
    <div class="p-2">
      <mat-accordion>
        <div
          class="header-row grid grid-cols-9 gap-4 bg-[#cdcdcd] table-title p-2 border-t-2 border-gray-300 rounded-t-lg">
          <p class="text-center font-medium">Início</p>
          <p class="text-center font-medium">Encerramento</p>
          <p class="text-center font-medium">Status</p>
          <p class="text-center font-medium">Usuário</p>
          <p class="text-center font-medium">Departamento</p>
          <p class="text-center font-medium">TME</p>
          <p class="text-center font-medium">TMA</p>
          <p class="text-center font-medium">TTA</p>
        </div>
        <mat-expansion-panel *ngFor="let attendance of pager.list" [expanded]="true">
          <mat-expansion-panel-header class="bg-[#fafbff]" (click)="updateMessages(attendance._id)">
            <div class="w-full grid grid-cols-9 gap-4 items-center">
              <div class="text-center">
                <p>{{ attendance?.createdAt | date:'dd/MM/yyyy HH:mm' }}</p>
              </div>

              <div class="text-center">
                <p *ngIf="attendance.status === 'CLOSED' || attendance.status === 'OVERTIME'">
                  {{ attendance.closedAt | date:'dd/MM/yyyy HH:mm' }}
                </p>
                <p *ngIf="attendance.status !== 'CLOSED' && attendance.status !== 'OVERTIME'">-</p>
              </div>

              <div class="text-center">
                <span *ngIf="attendance.status === 'CLOSED'"
                  class="px-[10px] py-[5px] font-light text-white rounded-lg bg-gray-500">
                  Encerrado
                </span>
                <span *ngIf="attendance.status === 'OVERTIME'"
                  class="px-[10px] py-[5px] font-light text-white rounded-lg bg-[#EF4444]">
                  Fora de horário
                </span>
                <span *ngIf="attendance.status === 'PENDING'"
                  class="px-[10px] py-[5px] font-dark text-white rounded-lg bg-orange-500">
                  Aguardando
                  <span *ngIf="attendance?.chatbotStatus?.automated" class="ml-2 px-[10px] py-[5px] bg-black">
                    Chatbot
                  </span>
                </span>
                <span *ngIf="attendance.status === 'IN_PROGRESS'"
                  class="px-[10px] py-[5px] font-light text-white rounded-lg bg-green-500">
                  Em andamento
                </span>
                <span *ngIf="attendance.status === 'PAUSED'"
                  class="px-[10px] py-[5px] font-light text-white rounded-lg bg-[#3B82F6]">
                  Pausado
                </span>
              </div>

              <div class="text-center">
                <p>{{attendance?.user?.name ?? '-'}}</p>
              </div>

              <div class="text-center">
                <p class="text-xs text-gray-500">{{attendance?.department?.name ?? '-'}}</p>
              </div>

              <div class="text-center">
                <p class="text-xs text-gray-500">{{getFormatDate(attendance?.kpis?.tme) ?? '-'}}</p>
              </div>

              <div class="text-center">
                <p class="text-xs text-gray-500">{{getFormatDate(attendance?.kpis?.tma) ?? '-'}}</p>
              </div>

              <div class="text-center">
                <p class="text-xs text-gray-500">{{getFormatDate(attendance?.kpis?.tmta) ?? '-'}}</p>
              </div>
            </div>
          </mat-expansion-panel-header>
          <chat-messages-component [pager]="pagerMessage" [attendance]="attendance" [messages]="messages"
            (messageLoadPage)="loadPageMessage(attendance._id)">
          </chat-messages-component>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</ng-template>

<paginator *ngIf="!data.openAttendanceHistoryUnique && pager.total > pager.perPage"
  class="flex justify-content-center mt-3 mb-3" [pager]="pager" (onPager)="loadPage($event)">
</paginator>