import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AbstractComponent, Attendance, ConfirmationComponent, Message, MessageHelper, MessageService, MessageTypeEnum, Pager, Traduct, UtilHelper } from 'lib-trend-core';
import { AppState } from '../../state/app.state';
import { getMessages } from '../../state/actions';

@Component({
  selector: 'menu-actions-component',
  templateUrl: './menu-actions.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MenuActionsComponent extends AbstractComponent implements OnInit {

  @Input() attendance: Attendance;
  @Input() message: Message;
  @Input() pager: Pager<Message>;
  @Input() isMessageAttendant: boolean;
  @Output('messageReply') messageReply: EventEmitter<Message> = new EventEmitter<Message>();
  @Output('messageEdit') messageEdit: EventEmitter<Message> = new EventEmitter<Message>();
  @Output('messagesReload') messagesReload: EventEmitter<boolean> = new EventEmitter<boolean>();

  showContainerEmoji: boolean = false;
  translator: Traduct;

  constructor(
    injector: Injector,
    private messageService: MessageService,
    private dialog: MatDialog,
    private store: Store<AppState>,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.translator = UtilHelper.getEmojiTranslator();
  }

  editMessage(message: Message): void {
    this.messageEdit.emit(message);
    this.closeMenu();
  }

  replyMessage(message: Message): void {
    this.messageReply.emit(message);
    this.closeMenu();
  }

  reactMessage(): void {
    this.toogleContainerEmoji();
  }

  deleteMessageForEveryone(message: Message): void {
    const idUser: unknown = message?.user;

    if (this.isAttendant() && idUser !== super.getCurrentUserUser()._id) {
      this.alertService.warning('Você não pode apagar uma mensagem que não é sua.');
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: 'Tem certeza que deseja apagar essa mensagem?',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.messageService.delete(message._id).subscribe({
          next: () => {
            this.alertService.success('Mensagem apagada com sucesso!')
            this.store.dispatch(getMessages({ page: this.pager.page, perPage: this.pager.perPage, idAttendance: this.attendance._id, idCompany: super.getIDCurrentCompany() }));
          },
        });
      };
    });
  }

  addEmoji(event: any): void {
    this.showContainerEmoji = false;
    this.messageService.reactMessage(MessageHelper.createReactToMessage(this.attendance, MessageTypeEnum.reaction, event.emoji.native, super.getIDCurrentUser()), event.emoji.native, this.message._id).subscribe({
      next: () => {
        this.store.dispatch(getMessages({ page: this.pager.page, perPage: this.pager.perPage, idAttendance: this.attendance._id, idCompany: super.getIDCurrentCompany() }));
        this.closeMenu();
      },
      error: (err) => {
        this.closeMenu();
      },
    });
  }

  toogleContainerEmoji(): void {
    this.showContainerEmoji = !this.showContainerEmoji;
  }

  private closeMenu(): void {
    const event = new CustomEvent('closeMenu');
    window.dispatchEvent(event);
  }

}
